import React from "react"
import { apiURL } from "../utilities/endpoints"

function addDashes(f) {
  var number = f.value.replace(/\D/g, "")
  return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
}

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      header: "Welcome Back",
      subtitle: "Log in with your phone number",
      placeholder: "555-555-5555",
      activeCount: 12,
      buttonText: "Next",
      input: "",
      code: null,
      phone: null,
      desiredInput: "phone",
      inputClass: "",
    }
    this._handleInputChange = this._handleInputChange.bind(this)
  }
  _handleInputChange = e => {
    if (this.state.desiredInput == "phone") {
      var number = addDashes(e.target)
      if (number.length <= this.state.activeCount) {
        this.setState({
          phone: number,
          input: number,
        })
      }
    } else if (this.state.desiredInput == "code") {
      console.log("HERE")
      var number = e.target.value
      if (number.length <= this.state.activeCount + 1) {
        this.setState({
          code: number,
          input: number,
        })
      }
    }
  }

  getCode = async function() {
    var number = this.state.input.replace(/\D/g, "")
    var twilioNumber = "+1" + number

    var request = apiURL + "/admin/login"
    console.log(request)
    console.log("GATSBY NETLIFY: ", process.env.GATSBY_NETLIFY)

    var response = await fetch(request, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: twilioNumber,
      }),
    })
    if (response.ok) {
      this.setState({
        header: "Verify your number",
        subtitle: "We texted you a verification code",
        placeholder: "XXXX",
        phone: twilioNumber,
        buttonText: "Log In",
        activeCount: 4,
        input: "",
        desiredInput: "code",
      })
    }
  }

  resendCode = async function() {
    var number = this.state.phone

    var request = url + "/admin/login"
    console.log(request)

    var response = await fetch(request, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: number,
      }),
    })
    if (response.ok) {
      this.setState({
        subtitle: "We resent a verification code",
        input: "",
        inputClass: "",
      })
    }
  }

  logIn = async function() {
    var request = apiURL + "/admin/code"
    console.log(request)

    var response = await fetch(request, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: this.state.phone,
        code: this.state.code,
      }),
    })
    if (response.ok) {
      window.location = "/lists"
    } else {
      console.log("setting error state")
      this.setState({
        inputClass: "error",
      })
    }
  }

  ErrorMessage() {
    return (
      <p>
        That's not the right code.{" "}
        <a href="/" onClick={this._handleResendCode}>
          Resend code?
        </a>
      </p>
    )
  }

  ResendCode() {
    return (
      <p>
        <a href="/" onClick={this._handleResendCode}>
          Resend code
        </a>
      </p>
    )
  }

  _handleClick = async e => {
    e.preventDefault()

    if (this.state.desiredInput == "phone") {
      this.getCode()
    }
    if (this.state.desiredInput == "code") {
      this.logIn()
    }
  }

  _handleResendCode = async e => {
    e.preventDefault()
    this.resendCode()
  }

  render() {
    return (
      <div className="Login">
        <img className="logo" src="logo.png" />
        <h1>{this.state.header}</h1>
        <p>{this.state.subtitle}</p>
        <input
          type="tel"
          placeholder={this.state.placeholder}
          value={this.state.input}
          onChange={this._handleInputChange}
          className={this.state.inputClass}
        ></input>
        {this.state.inputClass == "error" ? this.ErrorMessage() : null}
        <button
          disabled={this.state.input.length < this.state.activeCount}
          onClick={this._handleClick}
        >
          {this.state.buttonText}
        </button>
        {this.state.desiredInput == "code" ? this.ResendCode() : null}
      </div>
    )
  }
}

export default Login
