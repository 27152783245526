import { apiURL } from "./endpoints"

export async function checkIfSignedIn() {
  const url = apiURL + "/admin/auth" // TODO: Replace with basic check-auth route that doesn't do anything but return if cookie sesion is still valid
  const response = await fetch(url, {
    credentials: "include",
  })
  if (response.ok) {
    return response
  } else {
    return Error(response.statusText) // TODO: Replace with returning basic user info needed to display name etc
  }
}
