import { Link } from "gatsby"
import { default as React, useState } from "react"

const Nav = props => {
  function change(event) {
    window.location = event.target.value
  }

  return (
    <div className="Nav">
      {/* <img
        srcSet="/logo-mobile.png 450w,
             /logo.png 1586w"
        sizes="(max-width: 768px) 40px,
            460px"
        src="/logo.png"
        alt="Instant"
      ></img> */}
      <img className="logo" src="/logo.png" />
      <ul>
        <Link to="/broadcasts">
          <li>Broadcasts</li>
        </Link>
        <Link to="/lists">
          <li>Lists</li>
        </Link>
        <Link to="/">
          <li>Help</li>
        </Link>
      </ul>
      <p>
        <React.Fragment>
          {props.currentUser == false && "Sign In"}
          {props.currentUser && "Hello " + props.currentUser.name}
        </React.Fragment>
      </p>
      <select id="sectional_nav" onChange={change} value="">
        <option></option>
        <option value="/lists">Lists</option>
        <option value="/broadcasts">Broadcasts</option>
      </select>
    </div>
  )
}

export default Nav
