/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { default as React, useState, useEffect } from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import SEO from "./seo"
import Footer from "./footer"

import "./../stylesheets/index.sass"

import { checkIfSignedIn } from "../utilities/auth"
import Login from "../components/Login"

const Layout = props => {
  const [currentUser, setCurrentUser] = useState(null)

  async function updateSignedInNav() {
    const result = await checkIfSignedIn()

    if (result.ok) {
      var json = await result.json()
      setCurrentUser(json.admin)
      if (json && props.title == "Login") {
        window.location = "/lists"
      }
    }
  }

  useEffect(() => {
    updateSignedInNav()
  }, [])

  return (
    <div>
      <SEO {...props} />
      {!props.hideNav && <Nav currentUser={currentUser} />}
      <div>
        <main>{props.children}</main>
        {!props.hideFooter && <Footer />}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
