import { Link } from "gatsby"
import { get } from "lodash"
import { default as React, useState, useEffect } from "react"
import { Instagram } from "react-feather"

const Footer = props => {
  return <footer></footer>
}

export default Footer
